<template>
  <div>
    <admin-title></admin-title>
    <div class="top">
      <div class="t-content">
        <div class="left">
          <div class="num">
            <span class="title">WinC スカウトチケット数</span>
            <span class="now-num">{{topInfo.residueNum}}&nbsp;&nbsp;</span>
            <span class="total-num">/ {{ topInfo.totalNum }}</span>
          </div>
          <div class="date">
            <span class="title">購入日時</span>
            <span class="grey">{{topInfo.createTimeLabel}}</span>
          </div>
          <div class="date">
            <span class="title">送信期限</span>
            <span>{{topInfo.expirationDateLabel}}</span>
          </div>
        </div>
        <el-tooltip class="item" effect="dark" content="WinC Careerは学生一人ひとりに企業が向き合い、想いを伝えられるよう、一括送信はできない仕様です。" placement="bottom-end">
          <div class="right">
            <img src="../../assets/images/ae/ques_blue.png">
            <span>スカウトの想い</span>
          </div>
        </el-tooltip>

      </div>
    </div>
    <div class="container">
      <div class="page-title">
        <span>スカウト</span>
      </div>

      <div v-if="type == 1">
        <p class="title">送信設定</p>
        <div class="form from-data">
          <el-form ref="formData" :rules="formDataRules" :model="formData" label-width="0px">
            <div class="form-item">
              <div class="form-title">
                <span>保存リスト</span>
                <img src="../../assets/images/user.png" />
              </div>
              <el-form-item prop="saveListId">
                <el-select v-model="formData.saveListId" placeholder="">
                  <el-option
                      v-for="item in saveListSel"
                      :key="item.id"
                      :label="item.kbName"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>

            </div>
            <div class="form-item">
              <div class="form-title">
                <span></span>
              </div>
              <el-form-item prop="entryType">
                <el-radio-group @change="getEntrySel" style="width: 23.438rem" v-model="formData.entryType">
                  <el-radio :label="1 + ''">説明会</el-radio>
                  <el-radio :label="3 + ''">募集要項</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="form-item">
              <div class="form-title">
                <span>テンプレ名</span>
              </div>
              <el-form-item prop="tempId">
                <el-select v-model="formData.tempId" placeholder="">
                  <el-option
                      v-for="item in tempSelSel"
                      :key="item.id"
                      :label="item.kbName"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="form-item">
              <div class="form-title">
                <span>募集要項</span>
              </div>
              <el-form-item prop="jobHuntingId">
                <el-select v-model="formData.jobHuntingId" placeholder="">
                  <el-option
                      v-for="item in entrySel"
                      :key="item.id"
                      :label="item.kbName"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="form-item date-picker">
              <div class="form-title">
                <span>送信予定日時</span>
              </div>
              <el-form-item prop="sendDate">
                <el-date-picker
                    v-model="formData.sendDate"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm"
                    format="yyyy-MM-dd HH:mm"
                    :clearable="false"
                    placeholder="">
                </el-date-picker>
              </el-form-item>
            </div>
            <div @click="submitFormData" class="form-button">確認画面へ</div>
          </el-form>
        </div>
      </div>

      <div v-if="type == 2">
        <p class="title">送信設定 ＞ 確認画面</p>
        <div class="form">

          <el-form class="mail" ref="form" :model="form" label-width="8rem">
            <div class="mail-item">
              <div slot="label" class="form-title">
                <span>送信先</span>
                <img src="../../assets/images/deliverySettings/user_grey.png" />
              </div>
              <p>{{saveListDetail.name}}</p>
              <span>{{saveListDetail.userCnt}}名</span>
            </div>

            <div class="mail-item">
              <div slot="label" class="form-title">
                <span>カテゴリー</span>
              </div>
              <p v-if="formData.entryType == 1">説明会</p>
              <p v-else>募集要項</p>
            </div>

            <el-form-item>
              <div slot="label" class="form-title">
                <span>件 名</span>
              </div>
              <el-input disabled v-model="tempDetail.title"></el-input>
            </el-form-item>

            <el-form-item>
              <div slot="label" class="form-title">
                <span>本 文</span>
              </div>
              <el-input disabled :autosize="{ minRows: 12}"  type="textarea" v-model="tempDetail.bodyContent"></el-input>
            </el-form-item>

            <div class="mail-item">
              <div slot="label" class="form-title">
                <span>記事URL</span>
              </div>
              <span style="cursor: pointer" @click="nav()">{{kName}}</span>
            </div>

            <div class="mail-item">
              <div slot="label" class="form-title">
                <span>送信予定日時</span>
              </div>
              <p>{{formData.sendDate}}</p>
            </div>
          </el-form>


          <div class="buttons" v-if="isButton">
            <div @click="returnSubmit()" class="button-1">送信設定へ戻る</div>
            <div @click="submit()" class="button-2">送信予約</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
export default {
  name: "deliverySettings",

  data() {
    return {
      type: 1,
      topInfo: {},
      radio: '1',

      form: {
        textarea: 'こんにちは！株式会社ウインク人事担当の水戸部と申します。\n' +
            '\n' +
            '弊社は採用に力を入れており、様々な角度から採用を見つめ、これまで100社以上の企業様から支持をいただけました！\n' +
            'そんな弊社が開催している就職イベント「WinC Audition（ウインクオーディション）」がございます。\n' +
            '\n' +
            '東京・大阪の2拠点をメインに開催しており、6企業の社長・経営者様と直接お話を聞くことのできるイベントです。\n' +
            '今までで、50社以上の企業様がご参加いただき、●社はここで出会えた学生さんとマッチングして\n' +
            '即採用された過去の実績でございます。もし少しでも興味がありましたら、是非ご参加ください！\n' +
            '皆さまのご参加、心よりお待ち申し上げております。'
      },

      kName: '',

      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: '',


      saveListSel: [],
      tempSelSel: [],
      entrySel: [],

      userInfo: {},

      tempDetail: {},
      saveListDetail: {},

      formData: {
        entryType: '1'
      },

      formDataRules: {
        saveListId: { required: true, message: '「保存リスト」が入力されていません。', trigger: 'blur' },
        entryType: { required: true, message: '「カテゴリ」が入力されていません。', trigger: 'blur' },
        tempId: { required: true, message: '「テンプレ名」が入力されていません。', trigger: 'blur' },
        jobHuntingId: { required: true, message: '「募集要項」が入力されていません。', trigger: 'blur' },
        sendDate: { required: true, message: '「送信予定日時」が入力されていません。', trigger: 'blur' },
      },

      id: '',

      isButton: true
    }
  },

  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO));

    let type = this.$route.query.type;
    if((type == 1 || type == 2) && this.$route.query.id) {
      this.type = parseInt(type);
      this.id = parseInt(this.$route.query.id);
      this.getDetail();
      if(type == 2) this.isButton = false;
    } else {
      this.init()
    }
  },

  methods: {
    init() {
      this.getTopInfo();
      this.getSaveListSel();
      this.getTempSelSel();
      this.getEntrySel();

    },

    async getDetail() {
      await this.getTopInfo();
      await this.getSaveListSel();
      await this.getTempSelSel();
      await this.getEntrySel();

      let res = await this.$axios({url: `/compScout/sendMg/detail`, data: {id: this.id}, method: "post"});
      if(res.code == '000' && res.data) {
        res.data.entryType = res.data.entryType + '';
        this.formData = res.data;
      }

      if(this.type == 2) {
        this.entrySel.forEach(item => {
          if(item.id == this.formData.jobHuntingId) {
            this.kName = item.kbName;
          }

          // that.type = 2;

          this.getTempDetail();
          this.getSaveListDetail();
        })



      }

      this.$forceUpdate()
    },

    async getTopInfo() {
      let res = await this.$axios({url: `/compScout/top`, data: {token: this.userInfo.token}, method: "post"});
      if(res.code == '000' && res.data) {
        this.topInfo = res.data;
      }
    },

    nav() {
      let url = '';
      if(this.formData.entryType == 1) {
        url =  'https://winc-career.jp/meetingDetail?id=' + this.formData.jobHuntingId + '&cid=' + this.tempDetail.compId;
      } else if (this.formData.entryType == 3) {
        url =  'https://winc-career.jp/jobDetail?id=' + this.formData.jobHuntingId + '&cid=' + this.tempDetail.compId;
      }

      window.open(url)
    },


    submitFormData() {
      let that = this;
      // console.log(this.formData)

      this.entrySel.forEach(item => {
        if(item.id == this.formData.jobHuntingId) {
          this.kName = item.kbName;
        }
      })

      this.$refs['formData'].validate((valid) => {
        if (valid) {
          console.log(that.formData)
          that.type = 2;
          that.$forceUpdate()
          that.getTempDetail();
          that.getSaveListDetail();

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    async getTempDetail() {
      let res = await this.$axios({url: `/compScout/tempDetail`, data: {
          id: this.formData.tempId,
        }, method: "post"});
      if(res.code == '000') {
        this.tempDetail = res.data;
      }
    },

    async getSaveListDetail() {
      let res = await this.$axios({url: `/compScout/saveList/detail`, data: {
          id: this.formData.saveListId,
        }, method: "post"});
      if(res.code == '000') {
        this.saveListDetail = res.data;
      }
    },

    async getSaveListSel() {
      let res = await this.$axios({url: `/compScout/saveList/sel`, data: {compId: this.userInfo.compId}, method: "post"});
      if(res.code == '000') {
        this.saveListSel = res.data;
      }
    },

    async getTempSelSel() {
      let res = await this.$axios({url: `/compScout/tempSel`, data: {compId: this.userInfo.compId}, method: "post"});
      if(res.code == '000') {
        this.tempSelSel = res.data;
      }
    },

    async getEntrySel() {
      let res = await this.$axios({url: `/compScout/entrySel`, data: {
        compId: this.userInfo.compId,
          entryType: this.formData.entryType
        }, method: "post"});
      if(res.code == '000') {
        if(this.formData.jobHuntingId) {
          this.formData.jobHuntingId = '';
        }
        this.entrySel = res.data;
      }
    },

    async add() {
      this.formData.operatorId = this.userInfo.id;
      this.formData.compId = this.userInfo.compId;
      let res = await this.$axios({url: `/compScout/sendMg/add`, data: this.formData, method: "post"});
      if(res.code == '000') {
        this.$message({
          type: 'success',
          message: '送信予約しました'
        });
        this.$router.push('/index/ae')
        this.type = 1;
      }
    },

    async update() {
      this.formData.operatorId = this.userInfo.id;
      this.formData.compId = this.userInfo.compId;
      let res = await this.$axios({url: `/compScout/sendMg/update`, data: this.formData, method: "post"});
      if(res.code == '000') {
        this.$message({
          type: 'success',
          message: '送信予約しました'
        });
        this.$router.push('/index/ae')
        this.type = 1;
      }
    },

    returnSubmit() {
      this.type = 1;
    },

    submit() {
      let that = this;
      this.$confirm( '送信予約を確定しますか？', '配信後スカウト通数' + (this.topInfo.residueNum - this.saveListDetail.userCnt) + '通', {
        confirmButtonText: 'は い',
        cancelButtonText: 'キャンセル',
        // dangerouslyUseHTMLString: true,
        center: true,
        showClose: false,
        customClass: 'blue-box'
      }).then(() => {
        let type = that.$route.query.type;
        if((type == 1 || type == 2) && that.$route.query.id) {
          that.update();
        } else {
          that.add();
        }


      }).catch(() => {

      });
    }
  }
}
</script>

<style scoped src="../../style/deliverySettings.css">

</style>
